<template>
  <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" :defaultSort="{prop:'createTime',order:'descending'}" v-model:listQuery="listQuery">
    <template #condition>
      <el-form-item>
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">创单时间：</span>
            <el-date-picker
                clearable
                value-format="YYYY-MM-DD"
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-select clearable filterable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="请选择型号" v-model="listQuery.condition.modelId">
          <el-option v-for="item in modelOptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="工单完成状态" v-model="listQuery.condition.scanState" value-key="name">
          <el-option v-for="item in stateOptions" :key="item.name" :label="item.type" :value="item">
          </el-option>
        </el-select>
        <el-form-item>
          <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">查询工单</el-button>
        </el-form-item>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" type="success" size="mini" v-waves @click="handleCreate()">创建工单</el-button>
      </el-row>
    </template>
    <template #columns>
      <el-table-column label="工单号" align="center" prop="id"/>
      <el-table-column label="ODM 制造商" align="center">
        <template #default="scope">
          {{scope.row.manufacturer.name}}
        </template>
      </el-table-column>
      <el-table-column label="型号" align="center">
        <template #default="scope">
          {{scope.row.model.name}}
        </template>
      </el-table-column>
      <el-table-column label="物料/品类代码" align="center">
        <template #default="scope">
          {{scope.row.model.materialCode}}
        </template>
      </el-table-column>
      <el-table-column label="生产数量" align="center">
        <template #default="scope">
          {{scope.row.labelNumber === null || scope.row.labelNumber === undefined ? 0 : scope.row.labelNumber}}
        </template>
      </el-table-column>
      <el-table-column label="创单时间" align="center" prop="createTime"/>
      <el-table-column label="工单状态" align="center">
        <template #default="scope">
          {{scope.row.scanStatus.type}}
        </template>
      </el-table-column>
      <el-table-column label="完成时间" align="center" prop="finishTime">
        <template #default="scope">
          {{scope.row.finishTime === null || scope.row.finishTime === undefined ? "/" : scope.row.finishTime}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" class-name="fixed-width" align="center">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleDetail(scope.row)" v-waves>工单详情</el-button>
        </template>
      </el-table-column>
    </template>

    <el-dialog v-model="addScanOrderDialog" :show-close="true" :center="true" title="创建扫码工单" destroy-on-close :close-on-click-modal="false" width="400px">
      <el-form ref="scanOrderForm" :model="scanOrderForm" label-width="100px" label-position="right">
        <el-form-item label="型号" required>
          <el-select filterable style="margin-left: 10px;" class="filter-item" placeholder="请选择要生产的型号" v-model="scanOrderForm.modelId" @change="selectModel">
            <el-option v-for="item in modelOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="ODM制造商" required>-->
<!--          <el-select filterable style="margin-left: 10px;" class="filter-item" placeholder="请选择ODM制造商" v-model="scanOrderForm.manufacturerId">-->
<!--            <el-option v-for="item in manufacturerOptions" :key="item.id" :label="item.name" :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
          <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="submitLoading">{{submitLoading ? '提交中' : '确定'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import ListConditionTemplate from "@/components/templates/listConditionTemplate";
import commonApi from '@/apis/common'
import scanApi from "@/apis/scan/scan";
import adminApi from '@/apis/admin/admin'
export default {
  components: {ListConditionTemplate},
  data() {
    return {
      total: 0,
      rows: [],
      listQuery: {
        condition:{
          modelId: null,
          startTime: undefined,
          endTime: undefined,
          scanState: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      valueDate: null,
      manufacturerOptions: [],
      modelOptions: [],
      stateOptions: [{'name': 'CREATE','type': '新建'},{'name': 'START','type': '开始'},{'name': 'STOP','type': '暂停'},{'name': 'FINISH','type': '已完成'}],

      addScanOrderDialog: false,
      submitLoading: false,
      scanOrderForm: {
        modelId: null,
      },
      scanner: {},
      manufacturerId: '',
      accountType: ''
    }
  },
  created() {
    this.handleAsync()
  },
  mounted() {
  },
  methods: {
    async handleAsync() {
      await this.getAccountInfo()
      await this.getLoginUserInfo()
      await this.getAllModel()
      await this.getList()
    },
    async getAccountInfo() {
      await adminApi.getAccountInfo().then(res => {
        this.accountType = res.data.type
      })
    },
    async getLoginUserInfo() {
      await adminApi.getInfo().then(res => {
        this.scanner = res.data
        console.log(this.accountType)
        const loginUserType = this.accountType.name;
        if (loginUserType === 'MANUFACTURER') {
          this.manufacturerId = res.data.id
        }else if (loginUserType === 'SCANNER') {
          this.manufacturerId = res.data.manufacturer.id
        }else {
          this.manufacturerId = ''
        }
        this.listQuery.condition.manufacturerId = this.manufacturerId
        this.scanOrderForm.manufacturerId = this.manufacturerId
      }).catch(err => {
        console.log('获取当前登录用户信息失败')
      })
    },
    async getAllModel() {
      const condition = {manufacturerId: this.manufacturerId}
      await commonApi.getAllModels(condition).then(res => {
        this.modelOptions = res.data
      }).catch(() => {
        this.$message.warning("获取型号列表失败")
      })
    },
    // getAllManufacturer() {
    //   commonApi.getManufacturers(this.scanOrderForm.modelId).then(response => {
    //     this.manufacturerOptions = response.data
    //   })
    // },
    async getList() {
      console.log(this.listQuery)
      scanApi.getPaged(this.listQuery).then(res => {
        this.rows = res.data.content
        this.total = res.data.totalElements
      })
    },
    selectModel() {
      this.scanOrderForm.manufacturerId = this.scanner?.manufacturer?.id
      // this.manufacturerOptions = []
      // this.getAllManufacturer()
    },
    handlePageInfoChange() {
      this.getList()
    },
    handleFilter() {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList()
    },
    handleCreate() {
      this.scanOrderForm = {}
      if (this.accountType.name !== 'SCANNER') {
        this.$message.warning('该账号不是生产管理用户，无法创建工单')
        return false
      }
      this.addScanOrderDialog = true
    },
    handleDetail(row) {
      this.$router.push({
        path: '/produce/scan/info',
        query: {
          id: row.id
        }
      })
    },
    onCancelButtonClick(){
      this.addScanOrderDialog = false
      this.$refs.scanOrderForm.resetFields()
    },
    onSubmitButtonClick() {
      this.submitLoading = true
      console.log(this.scanOrderForm)
      scanApi.addScanOrder(this.scanOrderForm).then(response => {
        this.getList()
        this.addScanOrderDialog = false
        this.submitLoading = false
      }).catch(() => {
        // this.$message.error('创建失败')
      })
    },
  }
}
</script>

<style scoped>

</style>
