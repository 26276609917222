import api from '@/apis/api'


/**
 * 扫码生产相关api
 */
export class scanApi extends api {
    addScanOrder(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/save`,
            method: 'post',
            data: data
        })
    }
}

export default new scanApi('scan')
